export const config = {
  ENVIRONMENT: import.meta.env.PUBLIC_ENVIRONMENT,
  RELEASE: import.meta.env.PUBLIC_RELEASE,
  LOG_LEVEL: import.meta.env.PUBLIC_LOG_LEVEL,
  OPENFORT_PUBLISHABLE_KEY: import.meta.env.PUBLIC_OPENFORT_PUBLISHABLE_KEY,
  SHIELD_DEBUG: import.meta.env.PUBLIC_DEBUG === 'true',
  SHIELD_PUBLISHABLE_KEY: import.meta.env.PUBLIC_SHIELD_PUBLISHABLE_KEY,
  API_ORIGIN: import.meta.env.PUBLIC_API_ORIGIN,
  API_SESSION_KEY_ENDPOINT: import.meta.env.PUBLIC_API_SESSION_KEY_ENDPOINT,
  SENTRY_DSN: import.meta.env.PUBLIC_SENTRY_DSN,
};
